export const getSettings = (newProducts) => {
  return {
    slidesToScroll: 1,
    dots: false,
    draggable: true,
    swipeToSlide: true,
    slidesToShow: 5,
    infinite: newProducts.length >= 5,
    arrows: newProducts.length >= 5,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 5,
          infinite: newProducts.length >= 5,
          arrows: newProducts.length >= 5,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 4,
          infinite: newProducts.length >= 4,
          arrows: newProducts.length >= 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 4,
          infinite: newProducts.length >= 4,
          arrows: newProducts.length >= 4,
        },
      },
    ],
  }
}
