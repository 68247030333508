import React from "react";
import { getBanglaHTML } from "../utilities/Webview";

const MyOrderItem = (props) => {
	const { product_data, quantity, amount, rate } = props.items;
	const { title } = JSON.parse(product_data);

	return (
		<div className="p-0 m-0 d-flex justify-content-between align-items-center">
			<div className="d-flex flex-row ordered-product flex-grow-1 align-items-baseline">
				<div
					className="ordered-product-title text-mid"
					dangerouslySetInnerHTML={{
						__html: `${getBanglaHTML(title)}`,
					}}
				></div>
				<div className="ordered-product-quantity text-mid ms-1">
					(<span className="currency-mini">{"৳ "}</span>
					{rate} x {quantity}pcs.)
				</div>
			</div>
			<div className="bd-highlight order-item-price text-mid">
				<p className="p-0 m-0 me-0 pe-0">
					<span className="currency-mini">{"৳ "}</span>
					<span>{amount}</span>
				</p>
			</div>
		</div>
	);
};

export default MyOrderItem;
