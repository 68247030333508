const addonsReducer = (addons = { groupAddons: {}, groups: [] }, action) => {
  let item, tempAddons;
  switch (action.type) {
    case "toggle_addon":
      item = action.payload.item;
      const groupId = action.payload.groupId+'';
      const groupAddons = {...addons.groupAddons};
      if (groupAddons[groupId]) {
        const index = groupAddons[groupId].findIndex((addon) => addon.id === item.id);
        if (index !== -1){
          if(groupAddons[groupId].length===1) delete groupAddons[groupId];
          else groupAddons[groupId].splice(index, 1);
        }
        else groupAddons[groupId]=[...groupAddons[groupId],item];
      } else {
        groupAddons[groupId]=[item];
      }
      return {...addons,groupAddons};
    case "load_addons":
      const { type, groups } = action.payload;
      tempAddons = { groupAddons: {}, groups: [] };
      if (type === "single" && groups?.length) {
        if(groups[0].required) tempAddons.groups.push(groups[0].id);
      } else if (groups?.length) {
        groups.forEach((group) => {
          if (group.required) tempAddons.groups.push(group.id);
        });
      }
      return tempAddons;
    default:
      return addons;
  }
};
export default addonsReducer;
