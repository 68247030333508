import React from "react";
import "./Success.css";
import { Link, useLocation } from "react-router-dom";
import { parameterArray } from "../utilities/Webview";

const Success = (props) => {
	const location = useLocation();
	const params = parameterArray();

	return (
		<div className="container">
			<div className="jumbotron text-center text-middle">
				<i className="fa-5x pb-2 text-success fas fa-check-circle"></i>
				<h4>Thank You!</h4>
				<p className="lead px-1">
					<span>
						We received your order, one of our representatives will
						call you shortly for confirmation.
					</span>{" "}
					<Link to="/account/orders">
						<span className="text-decoration-underline text-primary">
							{location.state
								? location.state.invoice_number
								: params.invoice
								? params.invoice
								: ""}
						</span>
					</Link>
				</p>

				<Link to="/">
					<p className="lead">
						<button className="btn btn-primary btn-sm rounded-pill">
							Continue to Homepage
						</button>
					</p>
				</Link>
			</div>
		</div>
	);
};

export default Success;
