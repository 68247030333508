const useProfileFormValidation=()=>{
    const phoneValidation=(val)=>{
        const value = val.trim();
        let isValid = value.length;

		if (value.length > 0) {
			if (value.startsWith("0") && value.length === 11) {
			} else if (value.startsWith("1") && value.length === 10) {
			} else if (value.startsWith("8") && value.length === 13) {
			} else if (value.startsWith("+") && value.length === 14) {
			} else {
				isValid = false;
			}
		}
        return {value:value.length > 0 ? value : null,isValid};
    }
    const emailValidation=(val)=>{
        const value = val.trim();
        const isValid =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				String(value)
			) || value.length === 0;
        return {value:value.length > 0 ? value : null,isValid};
    }
    const nameValidation=(val)=>{
        const value = val.trim();
        const isValid=value.length >= 1;
        return {value:value.length > 0 ? value : null,isValid};
    }
    return {phoneValidation,emailValidation,nameValidation};
}

export default useProfileFormValidation