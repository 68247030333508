export const setOffer = (data) => async (dispatch) => {
  dispatch({
    type: "SET_OFFER",
    payload: data,
  });
};

export const sowOfferModal = (data) => async (dispatch) => {
  dispatch({
    type: "SET_OFFER_MODAL",
    payload: data,
  });
};

export const setUserProfile = (data) => async (dispatch) => {
  dispatch({
    type: "SET_USER_PROFILE",
    payload: data,
  });
};

export const setSelectedOffer = (data) => async (dispatch) => {
  dispatch({
    type: "SET_SELECTED_OFFER",
    payload: data,
  });
};
