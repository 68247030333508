import { Button, Modal } from "antd";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const VerifyLocationModal = ({
	isVerifyModalVisible,
	setIsVerifyModalVisible,
}) => {
	return (
		<Modal
			title="Location Confirmation"
			centered
			visible={isVerifyModalVisible}
			onOk={() => setIsVerifyModalVisible(false)}
			onCancel={() => setIsVerifyModalVisible(false)}
			style={{ borderRadius: "5px" }}
			okButtonProps={{ className: "webview-btn border-radius-5" }}
			cancelButtonProps={{ className: "d-none" }}
			closeIcon={
				<Button type="link" className="text-danger">
					<AiOutlineClose fontSize={18} />
				</Button>
			}
		>
			<div className="text-center fw-600">
				Please check your delivery location and enter phone number &
				address before placing order
			</div>
		</Modal>
	);
};

export default VerifyLocationModal;
