import React from "react";

const BackSVG = () => {
  return (
    <svg
      width="25"
      height="25"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      style={{width:'25px'}}
      class="text-secondary-color"
    >
      <path
        d="m162.52 26.25c-36.578 0-66.273 29.695-66.273 66.273v374.88c0 36.578 29.695 66.344 66.273 66.344h374.88c36.578 0 66.344-29.766 66.344-66.344v-374.88c0-36.578-29.766-66.273-66.344-66.273zm0 17.43h374.88c27.188 0 48.844 21.656 48.844 48.844v374.88c0 27.188-21.656 48.824-48.844 48.824l-374.88 0.003906c-27.188 0-48.758-21.641-48.758-48.824v-374.89c0-27.188 21.57-48.844 48.758-48.844zm159.7 120.69 0.003907 0.003906c-1.9648 0.21875-3.7969 1.0977-5.1953 2.4961l-107.87 106.9v-0.003906c-1.6523 1.6445-2.582 3.875-2.582 6.2031s0.92969 4.5625 2.582 6.2031l107.87 106.9c3.4102 3.3945 8.9258 3.3945 12.336 0 1.6445-1.6523 2.5586-3.8906 2.5469-6.2188s-0.95312-4.5547-2.6133-6.1875l-92.695-91.91h248.04c2.332 0.007812 4.5703-0.91406 6.2188-2.5625 1.6523-1.6484 2.5742-3.8906 2.5625-6.2227-0.007813-2.3203-0.9375-4.543-2.5859-6.1758-1.6484-1.6367-3.875-2.5469-6.1953-2.5391h-248.11l92.762-91.996h0.003907c1.6562-1.6328 2.5898-3.8555 2.6055-6.1797 0.011719-2.3242-0.90234-4.5586-2.5391-6.2109-1.8789-1.8711-4.5039-2.7891-7.1406-2.4922z"
        fill-rule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};

export default BackSVG;
