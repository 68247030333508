import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Divider, Input, Tooltip } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { React, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavbarCheckout from "../Navbar/NavbarCheckout";
import {
	API_ENDPOINT_ORDER_STORE,
	getBanglaHTML,
	parameters,
} from "../utilities/Webview";
import "./Checkout.css";

import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setShowMap } from "../../component/actions/LocationActions";
import { showAvailableOffers } from "../../utils/helper";
import { setSelectedOffer, sowOfferModal } from "../actions/OfferAction";
import CopyRight from "../Footer/CopyRight";
import Footer from "../Footer/Footer";
import GoogleMap from "../Map/googleMap";
import OfferModal from "../Offer/OfferModal";
import notMatchedIcon from "./not-matched.png";
import stockOutIcon from "./package-cancelled.svg";
import VerifyLocationModal from "./VerifyLocationModal";
import { handleProductPrice } from "../utilities/Calculator";
import { CiDeliveryTruck } from "react-icons/ci";
import { BsPhone } from "react-icons/bs";
import useProfileFormValidation from "../../hooks/useProfileFormValidation";
import { postProfile } from "../../api";

const Checkout = (props) => {
	const navigate = useNavigate();
	const { phoneValidation } = useProfileFormValidation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const paymentMethods = props.paymentMethods;
	const cartData = useSelector((state) => state.cart.data);
	const cartPrices = useSelector((state) => state.cart.prices);
	const updateCart = props.updateCart;
	const getData = props.getData;
	const categorys = props.categorys;
	const [startDate, setStartDate] = useState(new Date());
	const [handleMapModalClose] = props.locationProps;
	const location = useLocation();

	const userAreaName = useSelector(
		(state) => state.locationData.userAreaName
	);
	const selectedLocation = useSelector(
		(state) => state.locationData.selectedLocation
	);
	const showMap = useSelector((state) => state.locationData.showMap);
	const goolgeMapAddresses = useSelector(
		(state) => state.locationData.goolgeMapAddresses
	);
	const offers = useSelector((state) => state.offerData.offers);
	const user = useSelector((state) => state.offerData.userProfile);
	const selectedOffer = useSelector((state) => state.offerData.selectedOffer);

	const setShowOfferModal = () => {
		dispatch(sowOfferModal(true));
	};

	const profile = useSelector((state) => state.profile);
	const [duplicateProfile, setDuplicateProfile] = useState(null);
	useEffect(() => {
		const { value, isValid } = phoneValidation(profile.mobile ?? "");
		setDuplicateProfile({ ...profile, mobile: { value, isValid } });
	}, [profile]);

	const [address, setAddress] = useState(profile.address ?? "");
	const [additionalNote, setAdditionalNote] = useState("");
	const [showAddressPlaceholder, setShowAddressPlaceholder] = useState(true);
	const [showInstructionPlaceholder, setShowInstructionPlaceholder] =
		useState(true);
	const [paymentMethod, setPaymentMethod] = useState(
		paymentMethods.cash_on_delivery
			? "cash-on-delivery"
			: paymentMethods.shurjopay
			? "shurjopay"
			: "bkash"
	);

	const handleAddress = (e) => {
		setAddress(e.target.value);
	};

	// useEffect(() => {
	// 	setAddress(userAreaName);
	// }, [userAreaName]);

	const submitOrder = () => {
		axios
			.post(`${API_ENDPOINT_ORDER_STORE}?${parameters()}`, {
				address: address,
				payment_method: paymentMethod,
				delivery_date: startDate,
				note: additionalNote,
				goolgeMapAddresses: goolgeMapAddresses,
				selectedOfferId: selectedOffer ? selectedOffer.id : null,
				availableOffer: showAvailableOffers(offers, user).map(
					(offer) => offer.id
				),
			})
			.then(function (response) {
				if (response.data.redirect) {
					window.location.href = response.data.redirect_url;
				} else {
					updateCart("empty");
					getData();
					navigate("/success", { state: response.data.order[0] });
				}
				dispatch(setSelectedOffer(null));
			})
			.catch((err) => {
				navigate("/failed");
			});
	};

	const placeOrder = () => {
		const btnCheckout = document.querySelector(".btn-checkout");
		if (btnCheckout) btnCheckout.disabled = true;

		let minimumOrderPriceVal = true;
		let minimumOrderQuantityVal = true;

		if (selectedLocation !== null) {
			if (selectedLocation !== false) {
				if (selectedLocation.minimumOrderPrice !== null)
					minimumOrderPriceVal = checkMinimumOrderPrice();
				if (selectedLocation.minimumOrderQuantity !== null)
					minimumOrderQuantityVal = checkMinimumOrderQuantity();
			}
		}

		if (
			productAvailability &&
			!stockUnavailability &&
			!itemExceeded &&
			profileReady() &&
			minimumOrderPriceVal &&
			minimumOrderQuantityVal
		) {
			setIsLoading(true);
			if (duplicateProfile.mobile.value !== profile.mobile) {
				const { display_name, address, mobile, email } =
					duplicateProfile;
				postProfile({
					display_name,
					address,
					mobile: mobile.value,
					email,
				})
					.then(() => {
						dispatch({
							type: "update_profile",
							payload: {
								display_name,
								address,
								mobile: mobile.value,
								email,
							},
						});
						submitOrder();
					})
					.catch(() => {
						setIsLoading(false);
						toast.error("Unable to update mobile number!", {
							position: "top-center",
							autoClose: 1000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					});
			}
			submitOrder();
		}

		let delay = 0;
		if (!productAvailability) {
			toast.error("These products are not available in this area");
			delay += 1000;
		} else if (stockUnavailability) {
			toast.error("These products are out of the stock", {
				icon: <img src={stockOutIcon} />,
			});
			delay += 1000;
		} else if (itemExceeded) {
			toast.error(
				"These products quantity exceed the number remaining items",
				{
					icon: <img src={notMatchedIcon} />,
				}
			);
			delay += 1000;
		} else if (!profileReady()) {
			if (address.length === 0) {
				toast.error("Please enter your address to place your order.", {
					icon: <img src={notMatchedIcon} />,
				});
			} else {
				toast.error(
					"Please enter a valid mobile number to place your order.",
					{
						icon: <img src={notMatchedIcon} />,
					}
				);
			}
		} else if (!minimumOrderPriceVal) {
			toast.error(
				`You must order at least BDT ${selectedLocation.minimumOrderPrice} worth of products in this area for delivery.`
			);
			delay += 1000;
		} else if (!minimumOrderQuantityVal) {
			toast.error(
				`You must order at least ${selectedLocation.minimumOrderQuantity} quantity of products in this area.`
			);
			delay += 1000;
		}

		setTimeout(() => {
			if (btnCheckout) btnCheckout.disabled = false;
		}, delay);
	};

	const checkMinimumOrderPrice = () => {
		let total = Number(
			document
				.getElementsByClassName("cart-total-price")[1]
				.textContent.split(" ")[1]
		);

		if (selectedLocation !== null) {
			if (selectedLocation.minimumOrderPrice !== null) {
				return selectedLocation.minimumOrderPrice <= total;
			}
		}
	};

	const checkMinimumOrderQuantity = () => {
		let total = cartData.reduce((sum, t) => sum + t.quantity, 0);
		if (selectedLocation !== null) {
			if (selectedLocation.minimumOrderQuantity !== null) {
				return selectedLocation.minimumOrderQuantity <= total;
			}
		}
	};

	const profileReady = () => {
		/*
     profile.email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(profile.email))
    */
		return address.length > 0 && duplicateProfile?.mobile.isValid;
	};

	const handleAddressOnBlur = (e) => {
		if (e.target.value.length === 0) {
			setShowAddressPlaceholder(true);
		} else {
			setShowAddressPlaceholder(false);
		}
	};

	const handleInstructionOnBlur = (e) => {
		if (e.target.value.length === 0) {
			setShowInstructionPlaceholder(true);
		} else {
			setShowInstructionPlaceholder(false);
		}
	};

	const handleStockAvailability = (product) => {
		let currentProduct = product.details;
		if (product.variation_key !== null)
			currentProduct = product.details.variations[product.variation_key];
		let availableItems;
		if (currentProduct.show_stock == 1) {
			let totalQtn = 0;
			currentProduct.inventory.stocks.forEach((i) => {
				totalQtn += +i.quantity;
			});
			availableItems = totalQtn - currentProduct.sold;
			if (availableItems > 0) {
				if (availableItems < product.quantity) return availableItems;
				else return "instock";
			} else {
				return "stockout";
			}
		} else {
			if (currentProduct.inventory.stock_status == 1) {
				return "instock";
			} else return "stockout";
		}
	};
	const [productAvailability, setProductAvailability] = useState(true);
	const [stockUnavailability, setStockUnavailability] = useState(false);
	const [itemExceeded, setItemExceeded] = useState(false);

	const productAmountDescription = ({
		title,
		price,
		quantity,
		available,
		stockStatus,
		total,
		isAddonDescription,
	}) => (
		<>
			<div
				className="col-8"
				style={{ opacity: isAddonDescription ? ".5" : "1" }}
			>
				<div className="text-start d-flex flex-row align-items-center flex-grow-1">
					<div
						className="checkout-product-title"
						dangerouslySetInnerHTML={{
							__html: `${getBanglaHTML(title)}`,
						}}
					></div>
					<div className="checkout-product-quantity">
						<span className="ms-1">{"("}</span>
						<span className="currency-mini">{"৳ "}</span>
						<span>
							{price} x {quantity} pcs.
						</span>
						{")"}
					</div>
					{(!available || stockStatus !== "instock") && (
						<div className="d-flex align-items-center ms-1">
							{!available && (
								<Tooltip
									title="This product is not available in this area"
									color={"red"}
									className="me-2"
								>
									<ExclamationCircleTwoTone
										style={{
											fontSize: "11px",
											cursor: "pointer",
										}}
										twoToneColor="#eb2f96"
									/>
								</Tooltip>
							)}
							{stockStatus === "stockout" && (
								<Tooltip
									title="Product is out of the stock"
									color={"red"}
									className="me-2"
								>
									<img
										src={stockOutIcon}
										alt=""
										style={{
											cursor: "pointer",
											width: "11px",
											height: "11px",
										}}
									/>
								</Tooltip>
							)}
							{stockStatus !== "instock" &&
								stockStatus !== "stockout" && (
									<Tooltip
										title={`Maximum of ${stockStatus} items available`}
										color={"red"}
									>
										<img
											src={notMatchedIcon}
											alt=""
											style={{
												cursor: "pointer",
												width: "15px",
											}}
										/>
									</Tooltip>
								)}
						</div>
					)}
				</div>
			</div>
			<div
				className="col-4 text-end"
				style={{ opacity: isAddonDescription ? ".5" : "1" }}
			>
				<span className="currency-mini">{"৳ "}</span>
				<span className="product-cart">{total?.toFixed(2)}</span>{" "}
			</div>
		</>
	);

	const handleProductsOnLocationChange = () => {
		let productsAvailable = cartData.map((c) => {
			let found = false;

			let stockStatus = handleStockAvailability(c);
			if (
				stockStatus !== "instock" &&
				stockStatus !== "stockout" &&
				!itemExceeded
			)
				setItemExceeded(true);
			else if (stockStatus === "stockout" && !stockUnavailability)
				setStockUnavailability(true);
			if (c.details.shipping.available_areas === null) found = true;
			else if (c.details.shipping.available_areas !== null) {
				if (c.details.shipping.available_areas.length === 0) {
					found = true;
				} else if (selectedLocation) {
					found = c.details.shipping.available_areas.find(
						(area_id) => area_id === selectedLocation.id + ""
					);
				}
			}
			if (found) {
				return { ...c, available: true, stockStatus: stockStatus };
			} else {
				if (productAvailability) setProductAvailability(false);
				return { ...c, available: false, stockStatus: stockStatus };
			}
		});
		localStorage.setItem(
			"productsAvailable",
			JSON.stringify(productsAvailable)
		);
		return (
			<div className="container ps-3 text-small">
				<div className="row bd-highlight">
					{productsAvailable.map((product, index) => (
						<>
							{productAmountDescription({
								title:
									product.variation_key !== null
										? product.details.title +
										  " - " +
										  product.details.variations[
												product.variation_key
										  ].title
										: product.details.title,
								quantity: product.quantity,
								price: handleProductPrice(product),
								available: product.available,
								stockStatus: product.stockStatus,
								total: cartPrices.entries[index]?.total,
							})}
							{/* {product.addons.length ? (
								<div className="text-start flex-grow-1 opacity-50">
									Addons:{" "}
									{product.addons
										.map((addon) => addon.title)
										.join(", ")}
								</div>
							) : (
								<></>
							)} */}
							{product.addons.map((addon) => (
								<>
									{productAmountDescription({
										title: addon.title,
										price: addon.price,
										quantity: product.quantity,
										total:
											Number(addon.price) *
											product.quantity,
										available: true,
										stockStatus: "instock",
										isAddonDescription: true,
									})}
								</>
							))}
						</>
					))}
				</div>
			</div>
		);
	};

	//   dispatch(setProductsAvailable(cartProducts));

	useEffect(() => {
		setProductAvailability(true);
	}, [selectedLocation]);

	const handlePaymentMethodChange = (e) => {
		setPaymentMethod(e.target.value);
	};

	useEffect(() => {
		setProductAvailability(true);
	}, [selectedLocation]);
	const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(true);
	useEffect(() => {
		setIsVerifyModalVisible(true);
	}, []);
	const handlePhoneNumber = (e) => {
		const { value, isValid } = phoneValidation(e.target.value);
		setDuplicateProfile({
			...duplicateProfile,
			mobile: { value, isValid },
		});
	};

	return (
		<>
			<div className={"spin-div" + (isLoading ? "" : " d-none")}>
				<div className="lds-roller">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>

				<Link className="d-none" to="/success" id="success" />
				<Link className="d-none" to="/failed" id="failed" />
			</div>
			<div className={(isLoading ? "d-none" : "") + " home-scroll"}>
				<NavbarCheckout categorys={categorys}></NavbarCheckout>
				{/* User information  */}
				{/* <div className="mt-2">
					<User user={profile}></User>
				</div> */}
				<div
					className={
						"card-body cart my-3" +
						(cartData.length === 0 ? "" : " d-none")
					}
				>
					<div className="col-sm-12 empty-cart-cls text-center mt-0">
						{" "}
						<img
							src="https://cdn.iconscout.com/icon/free/png-256/shopping-cart-1761527-1498563.png"
							alt=""
							width="200"
							height="200"
							className="img-fluid mb-4 mr-3"
						/>
						<div className="m-3 cart-empty-text">
							Your Cart is Empty
						</div>
						<Link to="/">
							<button
								type="button"
								className="btn btn-primary btn-sm"
							>
								Explore Product
							</button>
						</Link>
					</div>
				</div>
				<div className={cartData.length === 0 ? "d-none" : ""}>
					<div>
						{/*  Location section */}
						<GoogleMap handleMapModalClose={handleMapModalClose} />

						{/* Verify Location section */}
						<VerifyLocationModal
							isVerifyModalVisible={isVerifyModalVisible}
							setIsVerifyModalVisible={setIsVerifyModalVisible}
						/>

						<div className="ps-2 pe-2 mt-2">
							<div className="p-2 pb-0 bd-highlight">
								<div className="text-checkout text-start user-text-overflow">
									Deliver To:{" "}
									<div>{profile.display_name}</div>
								</div>
								{/* <div
									className="current-location-card p-2"
									onClick={() =>
										dispatch(setShowMap(true))
									}
								>
									<div className="d-flex align-items-end justify-content-start mb-1">
										<ion-icon
											name="checkmark-circle"
											id="checkbox-icon"
										></ion-icon>{" "}
										<span className="inner-text">
											Delivery Location
										</span>
									</div>
									<div>
										<div
											className="delivery-location"
											onClick={() =>
												dispatch(setShowMap(true))
											}
										>
											<div className="location-text checkout-location-text d-flex align-items-center flex-start">
												<ion-icon
													name="location-outline"
													id="location-icon"
												></ion-icon>
												<div>{userAreaName}</div>
											</div>
										</div>
									</div>
								</div> */}

								<div className="mt-1 d-flex flex-column">
									<Input
										addonBefore={<BsPhone />}
										defaultValue={
											duplicateProfile?.mobile.value ?? ""
										}
										value={
											duplicateProfile?.mobile?.value ??
											""
										}
										placeholder="Mobile number"
										className="custom-input-group"
										size="middle"
										onChange={handlePhoneNumber}
									/>
									<small
										className={`text-danger align-self-start fw-bold mt-1 ${
											duplicateProfile?.mobile.isValid
												? "d-none"
												: ""
										}`}
									>
										Invalid mobile number!
									</small>
								</div>
								<div className="mt-2">
									<div className="input-group p-2 address-input-group">
										<div className="d-flex align-items-end justify-content-start">
											<CiDeliveryTruck />
											<span className="inner-text fw-bold pl-1">
												Full Address
											</span>
										</div>

										<div className="location-text checkout-location-text d-flex align-items-center flex-start">
											<input
												className="form-control address-input p-0 pl-1"
												aria-label="Address"
												placeholder={
													showAddressPlaceholder
														? "Enter Full Address"
														: ""
												}
												onFocus={() =>
													setShowAddressPlaceholder(
														!showAddressPlaceholder
													)
												}
												onBlur={handleAddressOnBlur}
												onChange={handleAddress}
												value={address}
											></input>
										</div>

										<div className="d-flex align-items-center">
											<p className="pl-1 mb-0 inner-text">
												{userAreaName}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Item information  */}
						<div className="d-flex flex-row justify-content-between bd-highlight ps-2 pe-2 mt-3 mb-2">
							<div className="p-2 pb-0 bd-highlight">
								<p className="fw-bold mb-0 text-checkout text-black">
									Your Order
								</p>
							</div>
							<div className="p-2 pb-0 bd-highlight">
								<Link to="/cart">
									<div className="d-flex justify-content-center align-items-center">
										<i className="fas fa-edit fa-sm edit-icon text-black"></i>
										<h6 className="pb-0 mb-0 ms-1 text-small text-edit text-black">
											{" "}
											Edit
										</h6>
									</div>
								</Link>
							</div>
						</div>

						{handleProductsOnLocationChange()}
						{showAvailableOffers(offers, user).length > 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									gap: "10px",
									padding: "12px",
								}}
							>
								<span
									style={{
										padding: "5px 10px 5px 10px",
										borderRadius: "5px",
									}}
									className="webview-btn"
									onClick={() => setShowOfferModal()}
								>
									{selectedOffer === null
										? "Apply Coupon"
										: selectedOffer.title +
										  " coupon applied"}
								</span>
								{selectedOffer !== null && (
									<MdDelete
										fontSize={20}
										color="red"
										onClick={() => {
											dispatch(setSelectedOffer(null));
											toast.error("Coupon removed");
										}}
									/>
								)}
							</div>
						)}
						<OfferModal />

						<div className="text-small container">
							<div className="bg-pricing">
								<div className="container ps-3 mb-0 mt-3 text-small">
									<div className="d-flex flex-row justify-content-between bd-highlight">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">
												Subtotal
											</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 me-1 cart-subtotal-price price-digit">
												<span className="currency-mini">
													{"৳ "}
												</span>
												<span>
													{(
														cartPrices.sub_total +
														cartPrices.addons_total
													).toFixed(2)}
												</span>
											</p>
										</div>
									</div>
								</div>
								{selectedOffer !== null && (
									<div className="container ps-3 mb-0">
										<div className="d-flex flex-row justify-content-between bd-highlight">
											<div className="bd-highlight">
												<p className="p-0 m-0 cart-subtotal-price text-black">
													Discount{" "}
												</p>
											</div>
											<div className="bd-highlight">
												<p className="p-0 m-0 me-1 cart-subtotal-price price-digit">
													<span>-</span>
													<span className="currency-mini">
														{"৳ "}
													</span>
													<span>
														{cartPrices.offer_total.toFixed(
															2
														)}
													</span>
												</p>
											</div>
										</div>
									</div>
								)}
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">
												VAT{" "}
											</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 me-1 cart-subtotal-price price-digit">
												<span>+</span>
												<span className="currency-mini">
													{"৳ "}
												</span>
												<span>
													{cartPrices.tax_total.toFixed(
														2
													)}
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight mb-2">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">
												Delivery Charge
											</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price me-1 price-digit">
												<span>+</span>
												<span className="currency-mini">
													{"৳ "}
												</span>
												<span>
													{cartPrices.shipping_total.toFixed(
														2
													)}
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className="divider-section">
									<Divider dashed className="my-3" />
								</div>
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight mb-2">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-total-price text-black">
												Total
											</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-total-price me-1">
												<span className="currency-logo">
													{"৳ "}
												</span>
												<span>
													{cartPrices.grand_total.toFixed(
														2
													)}
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/*  Apply coupon field */}
						{/* <div className="ps-2 pe-2 mt-3 mt-3">
							<div className="p-2 pb-0 bd-highlight">
							<Search placeholder="Apply coupon" onSearch={onSubmitCoupon} enterButton="Apply" />
							</div>
						</div> */}

						{/* Additional Instruction filed */}
						<div className="ps-2 pe-2 mt-3 mt-3">
							<div className="p-2 pb-0 bd-highlight">
								<div className="input-group address-input-group">
									<span
										className="input-group-text"
										id="address-field"
									>
										<ion-icon name="file-tray-full-outline"></ion-icon>
									</span>
									<Input
										className="form-control address-input"
										aria-label="Address"
										placeholder={
											showInstructionPlaceholder
												? "Additional Instruction"
												: ""
										}
										onFocus={() =>
											setShowInstructionPlaceholder(
												!showInstructionPlaceholder
											)
										}
										onBlur={handleInstructionOnBlur}
										onChange={(e) =>
											setAdditionalNote(e.target.value)
										}
									></Input>
								</div>
							</div>
						</div>

						{/* Payment information */}
						<p className="ms-3 mb-0 mt-3 text-checkout text-start">
							Payment Method
						</p>

						<div className="text-start ps-2 mt-2 mb-3">
							<div className="wrapper payment-wrapper d-flex flex-nowrap">
								{paymentMethods.cash_on_delivery && (
									<div className="me-2">
										<input
											type="radio"
											name="payment_method"
											value="cash-on-delivery"
											id="option-1"
											defaultChecked
											onClick={handlePaymentMethodChange}
										/>
										<label
											for="option-1"
											className="option option-1 btn"
										>
											<div className="radio-text-checkout">
												Cash on Delivery
											</div>
										</label>
									</div>
								)}
								{paymentMethods.shurjopay && (
									<div className="me-2">
										<input
											type="radio"
											name="payment_method"
											value="shurjopay"
											id="option-2"
											defaultChecked={
												!paymentMethods.cash_on_delivery &&
												!paymentMethods.bkash
											}
											onClick={handlePaymentMethodChange}
										/>
										<label
											for="option-2"
											className="option option-2 btn"
										>
											<div className="radio-text-checkout">
												Shurjopay
											</div>
										</label>
									</div>
								)}
								{paymentMethods.bkash && (
									<div className="me-2">
										<input
											type="radio"
											name="payment_method"
											value="bkash"
											id="option-3"
											defaultChecked={
												!paymentMethods.cash_on_delivery &&
												!paymentMethods.shurjopay
											}
											onClick={handlePaymentMethodChange}
										/>
										<label
											for="option-3"
											className="option option-3 btn"
										>
											<div className="radio-text-checkout">
												bKash
											</div>
										</label>
									</div>
								)}
							</div>
						</div>

						{/* Datepicker */}
						{/* <div className="ps-2 pe-2 pb-3">
							<div className="p-2 py-0 bd-highlight">
								<div className="text-checkout text-start">
									Delivery Date
								</div>
								<div className="mt-2 d-flex justify-content-between">
									<DatePicker
										defaultValue={moment(
											startDate,
											"DD/MM/YYYY"
										)}
										format={"DD/MM/YYYY"}
										onChange={(date) =>
											setStartDate(date)
										}
										inputReadOnly={true}
										className="date-picker-field"
										allowClear={false}
										disabledDate={(current) => {
											let customDate = moment(
												new Date(),
												"DD/MM/YYYY"
											);
											return (
												current &&
												current <
													moment(
														customDate,
														"DD/MM/YYYY"
													)
											);
										}}
									/>
									<Button
										type="primary"
										onClick={placeOrder}
										className="btn btn-md btn-checkout"
									>
										Place Order
									</Button>
								</div>
							</div>
						</div> */}
						{/* <div className="ps-2 pe-2 pb-3">
							<div className="p-2 py-0 bd-highlight">	
							<div className="mt-2 d-flex">
							<Button type="primary" onClick={placeOrder} className='btn btn-md rounded-pill'>Proceed to checkout</Button>
							</div>
							</div>
						</div> */}
					</div>
				</div>
				<CopyRight className="mt-3 mb-3" />

				{/* Modal for map  */}
				{/* <div onClick={(e) => e.stopPropagation()}>
				<Modal
					id="modal-product"
					className="modal-dialog-centered modal-p-bottom justify-content-center mx-auto align-items-center"
					show={show}
					onHide={() => setShow(false)}
					dialogClassName="modal-50w"
					aria-labelledby="product-modal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Google Map</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="modal-body-scroll">
							<div className="d-flex flex-column justify-content-center align-items-center ">
								<img
									src=""
									alt=""
									className="img-responsive resized"
								/>
							</div>
							<div className="d-flex flex-column justify-content-center ">
								<h3 className="justify-content-center py-2">
									Hello
								</h3>

								<p className="text-justify font-weight-bolder mx-auto modal-text-line">
									<small>This is map</small>
								</p>
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer className="d-flex justify-content-around">
						<Button variant="outline-primary" onClick={handleClose}>
							Confirm <i className="fas fa-check-circle"></i>
						</Button>
					</Modal.Footer>
				</Modal>
			</div> */}

				<Link className="d-none" to="/success" id="success" />
				<Link className="d-none" to="/failed" id="failed" />
			</div>

			{!isLoading ? <Footer placeOrder={placeOrder}></Footer> : <></>}
		</>
	);
};

export default Checkout;
