import React, { useState } from "react";
import CopyRight from "../Footer/CopyRight";
import Footer from "../Footer/Footer";
import GoogleMap from "../Map/googleMap";
import Navbar from "../Navbar/Navbar";
import CategoriesView from "./CategoriesView";
import "../Home/AllProducts/AllProducts.css";

const Categories = (props) => {
	const { categorys, setCategorys, cart, locationProps } = props;
	const [selectedLocation, handleMapModalClose] = locationProps;
	const [searchFieldClass, setSearchFieldClass] = useState("container-fluid");
	const [tempCategories, setTempCategories] = useState(categorys);
	const [latestQuery, setLatestQuery] = useState("");
	const handleOnScroll = () => {
		let scrollTop =
			document.getElementsByClassName("home-scroll")[0].scrollTop;
		if (scrollTop > 0) setSearchFieldClass("on-scroll-search");
		else setSearchFieldClass("container-fluid");
	};
	const search = (e, query) => {
		setTempCategories(
			categorys.filter((category) =>
				category.title
					.toLowerCase()
					.includes(e.target.value.toLowerCase())
			)
		);
		setLatestQuery(e.target.value);
	};

	return (
		<div>
			<div className="home-scroll" onScroll={handleOnScroll}>
				<GoogleMap handleMapModalClose={handleMapModalClose} />

				<Navbar
					categorys={categorys}
					setCategorys={setCategorys}
					selectedLocation={selectedLocation}
					search={search}
					latestQuery={latestQuery}
					searchFieldClass={searchFieldClass}
				></Navbar>
				{latestQuery.length !== 0 ? (
					<div className="text-center  mb-2">
						<div className="text-start ps-3">
							<div className="pt-2">
								<span className="search-results-title">
									Search results for
								</span>{" "}
								<span className="product-categories-text">
									{latestQuery}
								</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{tempCategories.length === 0 ? (
					<div className="empty-cart-cls text-center mt-0">
						{" "}
						<img
							src="https://cdn.iconscout.com/icon/free/png-256/shopping-cart-1761527-1498563.png"
							alt=""
							width="100"
							height="100"
							className="img-fluid mb-4 mr-3"
						/>
					</div>
				) : (
					<>
						<CategoriesView
							categorys={tempCategories}
							latestQuery={latestQuery}
						></CategoriesView>
					</>
				)}
				<CopyRight className="mt-3 mb-3" />
			</div>
			<Footer></Footer>
		</div>
	);
};

export default Categories;
