const LocationReducer = (
	locationData = {
		showMap: false,
		userCoordinate: false,
		userAreaName: false,
		selectedLocation: false,
		deliveryAreaRequired: false,
		deliveryCharge: false,
		deliveryAreaType: null,
		deliveryArea: [],
		districts: [],
		contactShippingAddresses: [],
		dropdownVal: null,
		isContactCoordinateSaved: null,
		goolgeMapAddresses: [],
	},
	action
) => {
	switch (action.type) {
		case "MAPS_ENABLED":
			return { ...locationData, maps_enabled: action.payload };
		case "SET_SHOWMAP":
			return { ...locationData, showMap: action.payload };
		case "SET_USER_COORDINATE":
			return { ...locationData, userCoordinate: action.payload };
		case "SET_USER_AREA_NAME":
			return { ...locationData, userAreaName: action.payload };
		case "SET_SELECTED_LOCATION":
			return { ...locationData, selectedLocation: action.payload };
		case "SET_DELIVERY_AREA_REQUIRED":
			return { ...locationData, deliveryAreaRequired: action.payload };
		case "SET_DELIVERY_CHARGE":
			return { ...locationData, deliveryCharge: action.payload };
		case "SET_DELIVERY_AREA_TYPE":
			return { ...locationData, deliveryAreaType: action.payload };
		case "SET_DELIVERY_AREA":
			return { ...locationData, deliveryArea: action.payload };
		case "SET_DISTRICTS":
			return { ...locationData, districts: action.payload };
		case "SET_CONTACT_SHIPPING_ADDRESSES":
			return {
				...locationData,
				contactShippingAddresses: action.payload,
			};
		case "SET_SELECT_DROPDOWN_VAL":
			return { ...locationData, dropdownVal: action.payload };
		case "SET_IS_CONTACT_COORDINATE_SAVED":
			return {
				...locationData,
				isContactCoordinateSaved: action.payload,
			};
		case "SET_GOOGLE_MAP_ADDRESS":
			return { ...locationData, goolgeMapAddresses: action.payload };

		default:
			return locationData;
	}
};

export default LocationReducer;
