import React from 'react';
import currencyLogo from '../../../../images/currency_logo.png';
import './SimilarProducts.css';
import productImg from '../../../../images/product_img.jpg';

function SimilarProducts(props) {
  const {thumbnail,title}=props.product;
  return (
    <div className="similar-product-category">
			<div
				className={ "card similar-product-card card-size d-flex align-items-center justify-content-start"
				}
			>
				{thumbnail ?
					<img
						src={thumbnail}
						className="similar-product-img"
						alt="..."
					/>:
					<img src={productImg} className="similar-product-img"
					alt=''/>
				}
			</div>
			<p className="category-title similar-products">{title}</p>
		</div>
  )
}

export default SimilarProducts;