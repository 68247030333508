import React from "react";

const HomeFillSVG = () => {
	return (
		<svg
			width="19"
			height="20"
			viewBox="0 0 19 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="text-white"
		>
			<path
				d="M6.64373 18.7821V15.7152C6.64372 14.9381 7.27567 14.3067 8.05844 14.3018H10.9326C11.7189 14.3018 12.3563 14.9346 12.3563 15.7152V18.7732C12.3562 19.4473 12.904 19.9951 13.5829 20H15.5438C16.4596 20.0023 17.3388 19.6428 17.9872 19.0007C18.6356 18.3586 19 17.4868 19 16.5775V7.86585C19 7.13139 18.6721 6.43471 18.1046 5.9635L11.443 0.674268C10.2785 -0.250877 8.61537 -0.220992 7.48539 0.745384L0.967012 5.9635C0.372741 6.42082 0.0175523 7.11956 0 7.86585V16.5686C0 18.4637 1.54738 20 3.45617 20H5.37229C5.69917 20.0023 6.01349 19.8751 6.24547 19.6464C6.47746 19.4178 6.60793 19.1067 6.60792 18.7821H6.64373Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default HomeFillSVG;
