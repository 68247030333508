const handleProductPrice = (product) => {
	let newPrice = product.details.price;
	let productPrice = parseFloat(newPrice);
	if (product.variation_key !== null) {
		productPrice = parseFloat(
			product.details.variations[product.variation_key].price
		);
		newPrice = productPrice;
		product.details.variations[
			product.variation_key
		].wholesale_pricing.forEach((pricing, index2) => {
			if (
				Number(pricing.quantity) <= product.quantity &&
				Number(pricing.wholesale_price) <= productPrice
			) {
				newPrice = Number(pricing.wholesale_price);
			}
		});
	}
	return newPrice;
};

export { handleProductPrice };
