import { handleProductPrice } from "../component/utilities/Calculator";
import { isInside } from "./circleCheck";
import { modifyCoordinateToXY, pointIsInPoly } from "./polygonCheck";

export const checkDeliveryArea = (coordinate, options) => {
	const result = [];
	const area = [];
	options?.forEach((op) => {
		let data = JSON.parse(op.data);
		if (data) {
			if (op.type === "radius") {
				let name = op.label;
				let center = {
					latitude: parseFloat(data.center.lat),
					longitude: parseFloat(data.center.lng),
				};
				let radius = parseFloat(data.radius);
				let circle = [{ name, center, radius }];
				let modifiedUserLocation = {
					latitude: coordinate.lat,
					longitude: coordinate.lng,
				};
				let check = isInside(modifiedUserLocation, circle);

				if (check) {
					area.push(op);
				}

				result.push(check);
			} else {
				let modifiedUserLocation = {
					x: coordinate.lat,
					y: coordinate.lng,
				};

				let modifiedPolygon = modifyCoordinateToXY(data.vertices);
				let check = pointIsInPoly(
					modifiedUserLocation,
					modifiedPolygon
				);

				if (check) {
					area.push(op);
				}

				result.push(check);
			}
		}
	});

	return area;
};

export const showAvailableOffers = (offers, user) => {
	let availableProducts = JSON.parse(
		localStorage.getItem("productsAvailable")
	);

	let availableOffers = [];
	let cartTotalPrice = 0;
	let cartTotalQuantity = 0;
	let cartProductIds = [];
	let cartCategoryIds = [];
	let cartProductQuantity = {};
	let cartCategoryProductQuantity = {};
	availableProducts.forEach((product) => {
		let price = handleProductPrice(product) * product.quantity;
		cartTotalPrice += price;
		cartTotalQuantity += product.quantity;
		let productId = product.details.id;
		cartProductIds.push(productId);
		cartProductQuantity[productId] = product.quantity;
		let categoryIds = product.details.categories;
		categoryIds.forEach((cat_id) => {
			if (cartCategoryProductQuantity.hasOwnProperty(cat_id)) {
				cartCategoryProductQuantity[cat_id] =
					cartCategoryProductQuantity[cat_id] + product.quantity;
			} else {
				cartCategoryProductQuantity[cat_id] = product.quantity;
			}
			if (!cartCategoryIds.includes(parseInt(cat_id))) {
				cartCategoryIds.push(parseInt(cat_id));
			}
		});
	});

	offers.forEach((offer) => {
		//offer assign limit
		let offerAssignLimit = false;
		if (offer.limit === 0) {
			offerAssignLimit = true;
		} else if (offer.offerUsed < offer.limit) {
			offerAssignLimit = true;
		}

		//minimum order amount
		let isMinimumOrderAmountfulfilled = false;
		if (offer.min_amount <= cartTotalPrice) {
			isMinimumOrderAmountfulfilled = true;
		}

		//minimum order quantity
		let isMinimumOrderQuantityfulfilled = false;
		if (offer.min_quantity <= cartTotalQuantity) {
			isMinimumOrderQuantityfulfilled = true;
		}

		//offer available for users
		let isUserfulfilled = false;
		if (offer.contactIds.length > 0) {
			let user_id = user.id;
			if (offer.contactIds.includes(user_id)) {
				isUserfulfilled = true;
			}
		} else {
			isUserfulfilled = true;
		}

		//offer for selected products and categories
		let isProductCategoryfulfilled = false;
		let selectedProductCategoryIds = [];
		if (offer.applicable_on === "product") {
			offer.categoryProductIds.forEach((id) => {
				if (cartProductIds.includes(id)) {
					isProductCategoryfulfilled = true;
					selectedProductCategoryIds.push(id);
				}
			});
		} else {
			offer.categoryProductIds.forEach((id) => {
				if (cartCategoryIds.includes(id)) {
					isProductCategoryfulfilled = true;
					selectedProductCategoryIds.push(id);
				}
			});
		}

		//individual product or categories minimum quantity
		let quantityChecked = true;
		let isPCQuantityFulfilled = [];
		if (isProductCategoryfulfilled === true) {
			if (offer.applicable_on === "product") {
				selectedProductCategoryIds.forEach((id) => {
					if (
						offer.categoryProductQuantities[id] <=
						cartProductQuantity[id]
					) {
						isPCQuantityFulfilled.push(true);
					} else {
						isPCQuantityFulfilled.push(false);
					}
				});
				if (isPCQuantityFulfilled.includes(false)) {
					quantityChecked = false;
				}
			} else {
				selectedProductCategoryIds.forEach((id) => {
					if (
						offer.categoryProductQuantities[id] <=
						cartCategoryProductQuantity[id]
					) {
						isPCQuantityFulfilled.push(true);
					} else {
						isPCQuantityFulfilled.push(false);
					}
				});
				if (isPCQuantityFulfilled.includes(false)) {
					quantityChecked = false;
				}
			}
		}

		if (
			offerAssignLimit === true &&
			isMinimumOrderAmountfulfilled === true &&
			isMinimumOrderQuantityfulfilled === true &&
			isUserfulfilled === true &&
			isProductCategoryfulfilled === true &&
			quantityChecked === true
		) {
			availableOffers.push(offer);
		}
	});

	return availableOffers;
};
