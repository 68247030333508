import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { noProductImg } from "../../utilities/noProductImg";
import { getBanglaHTML } from "../../utilities/Webview";

const { TabPane } = Tabs;

function ProductCard({ product, attributes }) {
	const [thumbnail, title, summary, price, regular_price] = product;
	const isListView = useSelector((state) => state.isListView);
	const productListView = (
		<div className="card w-100 product-card">
			<div className="row product-bar d-flex justify-content-between">
				<div className="box col-3 d-flex align-items-center p-0">
					{thumbnail ? (
						<img
							src={thumbnail}
							className="img-fluid ms-0 product-img"
							alt={title}
							onError={(e) => (e.target.src = noProductImg())}
						/>
					) : (
						<img
							src={noProductImg()}
							className="img-fluid ms-0 product-img"
							alt={title}
							onError={(e) => (e.target.src = noProductImg())}
						/>
					)}
				</div>
				<div className="col-6 row pb-2">
					<div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 product-title d-flex align-items-center mt-1">
						<div
							dangerouslySetInnerHTML={{
								__html: `${getBanglaHTML(title)}`,
							}}
						></div>
					</div>
				</div>
				<div className="col-3 p-0 d-flex align-items-center justify-content-center">
					<p className="text-center fw-bold product-price mb-0">
						{regular_price &&
							parseFloat(regular_price) > parseFloat(price) && (
								<div className="discount-price">
									<del>
										<span className="currency-logo">
											{"৳ "}
										</span>
										{regular_price}
									</del>
								</div>
							)}
						<small className="product-price">
							<span className="currency-logo">{"৳ "}</span>
							{price}
						</small>
					</p>
				</div>
			</div>
		</div>
	);
	const productGridView = (
		<div className="card w-100 product-card" style={{ padding: "4px" }}>
			<div className="img-attributes-container">
				<img
					src={thumbnail ?? noProductImg()}
					className="img-fluid grid-product-img"
					alt={title}
					onError={(e) => (e.target.src = noProductImg())}
					style={{
						height: "159.25px",
					}}
				/>
				{attributes?.length ? (
					<>
						<Tabs
							defaultActiveKey=""
							tabBarGutter={15}
							className="attributes-tabs"
						>
							{attributes.map((attr) => (
								<TabPane
									tab={
										<div className="btn btn-attributes radio-text border">
											<small className="attribute-value">
												{attr.values[0]}
											</small>
										</div>
									}
									key={attr.values[0]}
								></TabPane>
							))}
						</Tabs>
					</>
				) : (
					<></>
				)}
			</div>
			<div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 product-title mt-1 mb-2">
				<div
					dangerouslySetInnerHTML={{
						__html: `${getBanglaHTML(title)}`,
					}}
				></div>
			</div>

			<div className="col-12 p-0 d-flex align-items-center justify-content-start">
				<p className="text-center fw-bold text-black mb-0 w-100 d-flex justify-content-space-between">
					<small className="text-price">
						<span className="currency-logo">{"৳ "}</span>
						{price}
					</small>
					{regular_price &&
						parseFloat(regular_price) > parseFloat(price) && (
							<small className="text-price float-right">
								<del>
									<span className="currency-logo">
										{"৳ "}
									</span>
									{regular_price}
								</del>
							</small>
						)}
				</p>
			</div>
		</div>
	);
	return <>{isListView ? productListView : productGridView}</>;
}

export default ProductCard;
