import React from "react";

const HomeOutlineSVG = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-black"
    >
      <path
        d="M7.24946 17.3095V14.7539C7.24945 14.1039 7.75289 13.5757 8.37664 13.5713H10.6614C11.2882 13.5713 11.7962 14.1008 11.7962 14.7539V17.3175C11.7961 17.8694 12.2188 18.3204 12.7482 18.3334H14.2714C15.7899 18.3334 17.0208 17.0506 17.0208 15.4682V8.19821C17.0127 7.5757 16.7323 6.99113 16.2592 6.61087L11.0499 2.23775C10.1372 1.47631 8.83991 1.47631 7.92729 2.23775L2.74077 6.61881C2.26596 6.99753 1.98502 7.58307 1.97917 8.20614V15.4682C1.97917 17.0506 3.21011 18.3334 4.72856 18.3334H6.25176C6.79437 18.3334 7.23423 17.875 7.23423 17.3095"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeOutlineSVG;
