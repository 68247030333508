import {
	MinusOutlined,
	PlusOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import { List } from "antd";
import "antd/dist/antd.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../App";

function RightSideBar(props) {
	const categorys = props.categorys;
	const [toggledCategoryIcon, setToggledCategoryIcon] = useState(false);
	const handleCategoryClick = () => {
		document
			.getElementsByClassName("scrollable-category")[0]
			.setAttribute("aria-expanded", "false");
		document.getElementById("collapseCategory").className =
			"collapse multi-collapse";
		setToggledCategoryIcon(!toggledCategoryIcon);
	};
	const [companyInfo] = useContext(CompanyContext);
	return (
		<div
			className="offcanvas offcanvas-end w-75 bg-color-default"
			tabIndex="-1"
			id="navoffcanvas"
			aria-labelledby="navoffcanvasLabel"
		>
			<div className="offcanvas-header pt-3 pb-3 pr-3 ps-0">
				<h5 id="nav-title">{companyInfo.shop_name}</h5>
				<button
					type="button"
					className="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div className="offcanvas-body p-0 d-flex align-items-start">
				<ul className="navbar-nav pe-1 mb-lg-0 d-flex align-items-start">
					<Link to="/">
						<p
							className="nav-link nav-link-style"
							aria-current="page"
							data-bs-dismiss="offcanvas"
						>
							<ion-icon
								name="home-outline"
								id="home-icon"
							></ion-icon>
							Home
						</p>
					</Link>

					{/*  Scrollable category list with collapseable */}
					{/* <div className="d-flex justify-content-between align-items-center scrollable-category py-2 pe-2" onClick={()=>setToggledCategoryIcon(!toggledCategoryIcon)} data-bs-toggle="collapse" href="#collapseCategory" role="button" aria-expanded="false" aria-controls="collapseCategory">
                    <div className="d-flex align-items-center">
                        <UnorderedListOutlined />
                        <div className="ms-1 fw-bold">Category</div>
                    </div>
                    <div>
                        {toggledCategoryIcon ? <MinusOutlined/> :
                        <PlusOutlined/>}
                    </div>
                </div> */}
					<div
						className="collapse multi-collapse"
						id="collapseCategory"
					>
						<List
							size="small"
							bordered={false}
							dataSource={categorys}
							renderItem={(category) => (
								<Link to={`/category/${category.id}`}>
									<List.Item
										key={category.id}
										style={{
											cursor: "pointer",
											borderBottom: "none",
										}}
										className="rs-categories"
										data-bs-dismiss="offcanvas"
										onClick={handleCategoryClick}
									>
										{category.title}
									</List.Item>
								</Link>
							)}
						/>
					</div>

					<Link to="/account/orders">
						<p
							className="nav-link nav-link-style"
							data-bs-dismiss="offcanvas"
						>
							<ion-icon
								name="newspaper-outline"
								id="my-orders-icon"
							></ion-icon>
							My Orders
						</p>
					</Link>
				</ul>
			</div>
		</div>
	);
}

export default RightSideBar;
