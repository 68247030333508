import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import { API_ENDPOINT_FILTER, parameters } from "../../utilities/Webview";
import Categories from "../Categories/Categories";
import CustomCarousel from "../CustomCarousel/CustomCarousel";
import Products from "../Products/Products";
import Subcategories from "../Subcategories/Subcategories";
import GoogleMap from "../../Map/googleMap";
import "./AllProducts.css";
import CopyRight from "../../Footer/CopyRight";
import { useSelector } from "react-redux";

const Home = (props) => {
	const [subcategories, setSubcategories] = useState([]);
	const [opened, setOpened] = useState([]);
	const [searchFieldClass, setSearchFieldClass] = useState("container-fluid");

	const [featuredOpened, setFeaturedOpened] = useState(true);

	const [categoricalProductsTitle, setCategoricalProductsTitle] =
		useState("Featured");

	const [selectedLocation, handleMapModalClose] = props.locationProps;
	const featuredProducts = props.featuredProducts;

	const allProducts = props.allProducts;
	const categorys = props.categorys;
	const setCategorys = props.setCategorys;
	const products = props.products;
	const setProducts = props.setProducts;
	const expanded = props.expanded;
	const filterProducts = props.filterProducts;

	const appendOpened = (category_id, parent_id) => {
		// let open = opened;
		// if (parent_id) open.push(parent_id);
		// open.push(category_id);
		// setFeaturedOpened(false);
		// setOpened(open);

		let open = [];
		if (opened.includes(category_id)) {
			let found = false;
			open = opened.filter(function (value, index) {
				if (!found) found = value === category_id;
				return !found;
			});
			open.push(category_id);
		} else if (parent_id && opened.includes(parent_id)) {
			let found = false;
			open = opened.filter(function (value, index) {
				if (!found) found = value === parent_id;
				return !found;
			});
			open.push(parent_id);
			open.push(category_id);
		} else {
			open.push(category_id);
		}

		setFeaturedOpened(false);
		setOpened(open);

		// if (open.length === 0) {
		// 	filterProducts(allProducts);
		// 	setSubcategories([]);
		// 	setCategoricalProductsTitle("Featured");
		// 	setFeaturedOpened(true);
		// } else setFeaturedOpened(false);
		// setOpened(open);
	};

	const [latestQuery, setLatestQuery] = useState("");
	const [searchTimeout] = useState([null]);

	const search = (e, query) => {
		if (searchTimeout[0]) clearTimeout(searchTimeout[0]);
		searchTimeout[0] = setTimeout(() => {
			axios
				.get(`${API_ENDPOINT_FILTER}?${parameters()}&search=${query}`)
				.then((res) => {
					var products = res.data.products;
					if (query.length === 0) {
						filterProducts(allProducts);
					} else {
						filterProducts(products);
					}
					setLatestQuery(query);
				});
		}, 500);
	};

	useEffect(() => {
		setOpened([]);
		setFeaturedOpened(true);
	}, [selectedLocation]);

	const handleOnScroll = () => {
		let scrollTop =
			document.getElementsByClassName("home-scroll")[0].scrollTop;
		if (scrollTop > 0) setSearchFieldClass("on-scroll-search");
		else setSearchFieldClass("container-fluid");
	};

	return (
		<div>
			<div className="home-scroll" onScroll={handleOnScroll}>
				<GoogleMap handleMapModalClose={handleMapModalClose} />

				<Navbar
					categorys={categorys}
					setCategorys={setCategorys}
					selectedLocation={selectedLocation}
					search={search}
					latestQuery={latestQuery}
					searchFieldClass={searchFieldClass}
				></Navbar>

				{latestQuery.length === 0 && <CustomCarousel></CustomCarousel>}

				{latestQuery.length !== 0 ? (
					<div className="text-center  mb-2">
						<div className="text-start ps-3">
							<div className="pt-2">
								<span className="search-results-title">
									Search results for
								</span>{" "}
								<span className="product-categories-text">
									{latestQuery}
								</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{latestQuery.length === 0 && (
					<div className="pt-2">
						<Categories
							filterProducts={filterProducts}
							categorys={categorys}
							opened={opened}
							appendOpened={appendOpened}
							setSubcategories={setSubcategories}
							expanded={expanded}
							featuredProducts={featuredProducts}
							setProducts={setProducts}
							setOpened={setOpened}
							featuredOpened={featuredOpened}
							setFeaturedOpened={setFeaturedOpened}
							setCategoricalProductsTitle={
								setCategoricalProductsTitle
							}
						></Categories>
						<Subcategories
							filterProducts={filterProducts}
							categorys={categorys}
							opened={opened}
							appendOpened={appendOpened}
							setSubcategories={setSubcategories}
							expanded={expanded}
							setCategoricalProductsTitle={
								setCategoricalProductsTitle
							}
						></Subcategories>
					</div>
				)}

				{products.length === 0 ? (
					<div className="empty-cart-cls text-center mt-0">
						{" "}
						<img
							src="https://cdn.iconscout.com/icon/free/png-256/shopping-cart-1761527-1498563.png"
							alt=""
							width="100"
							height="100"
							className="img-fluid mb-4 mr-3"
						/>
					</div>
				) : (
					<>
						{/* <div className="d-flex pe-4 justify-content-between align-items-center container">
            <h6 className="font-weight-bold">View As</h6>
            <ImList fontSize={15}/>
          </div> */}
						<Products
							products={products}
							subcategories={subcategories}
							latestQuery={latestQuery}
							categoricalProductsTitle={categoricalProductsTitle}
						></Products>
					</>
				)}
				<CopyRight className="mt-3 mb-3" />
			</div>
			<Footer></Footer>
		</div>
	);
};

export default Home;
