import React from "react";

const CartFillSVG = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-white"
    >
      <path
        d="M3.6 9.6C2.937 9.6 2.406 10.137 2.406 10.8C2.406 11.463 2.937 12 3.6 12C4.263 12 4.8 11.463 4.8 10.8C4.8 10.137 4.263 9.6 3.6 9.6ZM0 0V1.2H1.2L3.357 5.751L2.547 7.221C2.454 7.395 2.4 7.59 2.4 7.8C2.4 8.463 2.937 9 3.6 9H10.8V7.8H3.855C3.771 7.8 3.705 7.734 3.705 7.65C3.705 7.623 3.711 7.599 3.723 7.578L4.26 6.6H8.73C9.18 6.6 9.573 6.351 9.78 5.982L11.925 2.088C11.973 2.004 12 1.905 12 1.8C12 1.64087 11.9368 1.48826 11.8243 1.37574C11.7117 1.26321 11.5591 1.2 11.4 1.2H2.529L1.959 0H0ZM9.6 9.6C8.937 9.6 8.406 10.137 8.406 10.8C8.406 11.463 8.937 12 9.6 12C10.263 12 10.8 11.463 10.8 10.8C10.8 10.137 10.263 9.6 9.6 9.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CartFillSVG;
