import { Button } from "antd";
import React from "react";
import { ImList } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import GridSVG from "../../SVGIcons/GridSVG";
import Product from "../Product/Product";
import "./Products.css";

const Products = (props) => {
	const products = props.products;
	const latestQuery = props.latestQuery;
	const dispatch = useDispatch();
	const isListView = useSelector((state) => state.isListView);
	const toggleProductView = () => {
		dispatch({ type: "update_product_view" });
	};
	// if (
	// 	subcategories.length > 0 &&
	// 	products.length > 0
	// ) {
	// 	return (
	// 		<div className="container">
	// 			{subcategories.map((subcategory, index) => (
	// 				<div>
	// 					<h6 className="text-start subcategory-title my-2">{subcategory.title}</h6>

	// 					{products.map((product, index2) => {
	// 						if (
	// 							product.categories.includes("" + subcategory.id)
	// 						) {
	// 							return (
	// 								<Product
	// 									key={index2}
	// 									product={product}
	// 									cardType='Product'
	// 								></Product>
	// 							);
	// 						}

	// 						return "";
	// 					})}
	// 				</div>
	// 			))}
	// 		</div>
	// 	);
	// }

	return (
		<div className="container">
			<div className={!isListView ? "row" : ""}>
				{products.map((product, index) => (
					<div
						className={
							(!isListView ? "col-6" : "") +
							((index + 1) % 2 === 0 && !isListView
								? " ps-1"
								: " pe-1")
						}
					>
						<Product
							key={product.id}
							product={product}
							cardType="Product"
						></Product>
					</div>
				))}
			</div>
		</div>
	);
};

export default Products;
