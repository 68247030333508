import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { showAvailableOffers } from "../../utils/helper";
import { setSelectedOffer, sowOfferModal } from "../actions/OfferAction";

const OfferModal = () => {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");
  const offers = useSelector((state) => state.offerData.offers);
  const showOfferModal = useSelector((state) => state.offerData.showOfferModal);
  const user = useSelector((state) => state.offerData.userProfile);
  const selectedOffer = useSelector((state) => state.offerData.selectedOffer);
  const hideModalOffer = () => dispatch(sowOfferModal(false));

  const setListOption = (offer) => {
    let listArr = [];

    // offer type
    let type = "";
    if (offer.type === 1) {
      type += "৳ ";
      type += offer.amount;
    } else {
      type += offer.amount;
      type += "%";
    }
    type += " discount";

    listArr.push(type);

    //min amount
    if (offer.min_amount !== 0) {
      let min_amount = "Min Order amount ৳ " + offer.min_amount;
      listArr.push(min_amount);
    }

    //min quantity
    if (offer.min_quantity !== 0) {
      let min_quantity = "Min Order quantity " + offer.min_quantity;
      listArr.push(min_quantity);
    }

    return listArr;
  };

  const handleCouponSubmit = (type, offer) => {
    if (type === "code") {
      let sOffer = null;
      let offersAvailable = showAvailableOffers(
        offers,
        user
      );
      if (couponCode === "") {
        toast.error("Coupon code is empty");
      } else {
        offersAvailable.forEach((offer) => {
          let offerCouponCode = offer.coupon_code;
          if (offerCouponCode === couponCode) {
            sOffer = offer;
          }
        });
        if (sOffer !== null) {
          // localStorage.setItem('selectedOffer', JSON.stringify(sOffer))
          dispatch(setSelectedOffer(sOffer));
          dispatch(sowOfferModal(false));
          setCouponCode("");
          toast.success("Coupon code applied successfully");
        } else {
          toast.error("Coupon code is not available");
        }
      }
    } else {
      dispatch(setSelectedOffer(offer));
      dispatch(sowOfferModal(false));
      toast.success("Coupon applied successfully");
    }
  };

  const buttonStyle = {
    backgroundColor: "#FFDD02",
    border: "none",
    borderRadius: "5px",
    padding: "4px 10px 4px 10px",
  };
  const buttonStyle1 = {
    backgroundColor: "#FFDD02",
    border: "none",
    borderRadius: "5px",
    padding: "4px 10px 4px 10px",
    height: "32px",
  };
  const buttonStyleSelected = {
    backgroundColor: "#1D8F1D",
    border: "none",
    color: "white",
    borderRadius: "5px",
    padding: "4px 10px 4px 10px",
    height: "32px",
  };
  const headingStyle = {
    fontSize: "16px",
    fontWeight: "600",
  };
  const offerNameStyle = {
    color: "#0F7470",
    fontSize: "17px",
    fontWeight: "600",
  };
  const offerDivStyle = {
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    borderRadius: "5px",
  };

  useEffect(() => {
    setTimeout(() => {
      const modalContent = document.querySelector(".modal-content");
      if (modalContent) modalContent.style.backgroundColor = "#F3F3F3";
    }, 200);
  }, []);

  return (
    <Modal
      title="Apply Coupon"
      centered
      visible={showOfferModal}
      style={{ borderRadius: "5px" }}
      bodyStyle={{backgroundColor:'#f3f3f3'}}
      className='offer-modal'
      footer={null}
      closeIcon={
        <Button type="link" className="text-danger" onClick={hideModalOffer}>
          <AiOutlineClose fontSize={18} />
        </Button>
      }
    >
      <div className="">
        <div className="d-flex" style={{ gap: "10px" }}>
          <input
            className="form-control"
            placeholder="Enter coupon code"
            value={couponCode}
            onInput={(e) => setCouponCode(e.target.value)}
          />
          <button
            style={buttonStyle}
            className="webview-btn"
            onClick={() => handleCouponSubmit("code")}
          >
            Apply
          </button>
        </div>
        <p className="mt-4 mb-0" style={headingStyle}>
          Available Coupons
        </p>
        <hr className="mt-2 mb-3"/>
        <div className="d-flex flex-column" style={{ gap: "8px" }}>
          {showAvailableOffers(offers, user).length < 0 ? (
            <p>No offer available</p>
          ) : (
            showAvailableOffers(offers, user)?.map(
              (offer) => (
                <div
                  style={offerDivStyle}
                  className="d-flex justify-content-between align-items-center px-4 py-2 offer-container"
                >
                  <div className="d-flex flex-column">
                    <span style={offerNameStyle}>{offer.title}</span>
                    <small>{setListOption(offer).join(' with ')}</small>
                  </div>
                  <div className="vertical-divider mx-4"></div>
                  <button
                    style={
                      selectedOffer
                        ? selectedOffer.id === offer.id
                          ? buttonStyleSelected
                          : buttonStyle1
                        : buttonStyle1
                    }
                    onClick={() => handleCouponSubmit("btn", offer)}
                    disabled={
                      selectedOffer
                        ? selectedOffer.id === offer.id
                          ? true
                          : false
                        : false
                    }
                    className='webview-btn'
                  >
                    {selectedOffer
                      ? selectedOffer.id === offer.id
                        ? "Applied"
                        : "Apply"
                      : "Apply"}
                  </button>
                </div>
              )
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OfferModal;
