import { React } from "react";
import userImg from "../../images/user.png";
import "./User.css";
import { useSelector } from "react-redux";

const User = () => {
	const user = useSelector((state) => state.profile);

	return (
		<div>
			<div className="container-fluid mt-3">
				<div className="bg-white user-information py-2">
					<div className="d-flex justify-content-left flex-row bd-highlight">
						<div className=" bd-highlight ms-3 my-auto">
							{user.image ? (
								<img
									src={user.image}
									alt=""
									className="img-dp rounded-circle"
									onError={(e) => (e.target.src = userImg)}
								/>
							) : (
								<img
									src={userImg}
									className="img-dp rounded-circle"
									alt=""
									onError={(e) => (e.target.src = userImg)}
								/>
							)}
						</div>
						<div className="bd-highlight ms-3 user-text d-flex align-items-start justify-content-center flex-column">
							<h6 className="p-0 m-0">{user.display_name}</h6>
							<p className="p-0 m-0 text-muted user-text-overflow">
								{user.email}
							</p>
							<p className="p-0 m-0 text-muted user-text-overflow">
								{user.mobile}
							</p>
							{/* <a className="p-0 m-0 text-link">Not You?</a> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default User;
