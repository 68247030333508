import { Button, Tabs } from "antd";
import React from "react";
import { TabPane } from "react-bootstrap";
import { ImList } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Home/Product/Product.css";
import GridSVG from "../SVGIcons/GridSVG";
import { noProductImg } from "../utilities/noProductImg";
import { getBanglaHTML } from "../utilities/Webview";

const CategoriesView = ({ categorys }) => {
	const dispatch = useDispatch();
	const isListView = useSelector((state) => state.isListView);
	const toggleProductView = () => {
		dispatch({ type: "update_product_view" });
	};
	const categoryListView = ({
		thumbnail,
		title,
		description,
		products_count,
	}) => (
		<div className="card w-100 product-card">
			<div className="row product-bar d-flex justify-content-between ml-0">
				<div className="box col-3 d-flex align-items-center p-0">
					{thumbnail ? (
						<img
							src={thumbnail}
							className="img-fluid ms-0 product-img"
							alt={title}
							onError={(e) => (e.target.src = noProductImg())}
						/>
					) : (
						<img
							src={noProductImg()}
							className="img-fluid ms-0 product-img"
							alt={title}
							onError={(e) => (e.target.src = noProductImg())}
						/>
					)}
				</div>
				<div className="col-9 row pb-2">
					<div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 mt-1 d-flex align-items-center">
						<div
							className="me-1 product-title"
							dangerouslySetInnerHTML={{
								__html: `${getBanglaHTML(title)}`,
							}}
						></div>
						<span className="product-count">
							({products_count})
						</span>
					</div>
				</div>
			</div>
		</div>
	);
	const categoryGridView = ({
		thumbnail,
		title,
		products_count,
		offer_categories,
	}) => (
		<div className="card w-100 product-card p-10">
			<div className="img-offers-container">
				{thumbnail ? (
					<img
						src={thumbnail}
						className="img-fluid grid-product-img"
						alt={title}
						onError={(e) => (e.target.src = noProductImg())}
					/>
				) : (
					<img
						src={noProductImg()}
						className="img-fluid grid-product-img"
						alt={title}
						onError={(e) => (e.target.src = noProductImg())}
					/>
				)}
				<Tabs
					defaultActiveKey=""
					tabBarGutter={15}
					className="offers-tabs"
				>
					{offer_categories.map(
						(offer_category, index) =>
							offer_category.offer && (
								<TabPane
									tab={
										<div className="btn btn-attributes radio-text border">
											<small className="attribute-value">
												{offer_category.offer.title}
											</small>
										</div>
									}
									key="test-attribute"
								></TabPane>
							)
					)}
				</Tabs>
			</div>
			<div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 mt-1 d-flex align-items-center">
				<div
					className="me-1 product-title"
					dangerouslySetInnerHTML={{
						__html: `${getBanglaHTML(title)}`,
					}}
				></div>
				<span className="product-count">({products_count})</span>
			</div>
		</div>
	);
	const navigate = useNavigate();
	const redirectToCategory = (id) => {
		navigate(`/category/${id}`);
	};
	return (
		<div className="container">
			<div className="d-flex align-items-center justify-content-between">
				<div className="text-start categorical-products-title my-2">
					Categories
				</div>
			</div>
			<div className={!isListView ? "row" : ""}>
				{categorys.map((category, index) => (
					<div className={!isListView ? "col-6" : ""}>
						<div
							className="d-flex justify-content-between pb-2"
							onClick={() => redirectToCategory(category.id)}
						>
							{isListView
								? categoryListView(category)
								: categoryGridView(category)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CategoriesView;
