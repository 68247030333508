const CartReducer = (
	cart = {
		data: [],
		offer: null,
		prices: {
			sub_total: 0.0,
			addons_total: 0.0,
			offer_total: 0.0,
			discount_total: 0.0,
			tax_total: 0.0,
			shipping_total: 0.0,
			grand_total: 0.0,
			entries: [],
		},
		lastUpdated: null,
	},
	action
) => {
	let cartData = [...cart.data];
	switch (action.type) {
		case "SET_CART_DATA":
			cartData = action.payload;
			return { ...cart, data: cartData, lastUpdated: Date.now() };
		case "CLEAR_CART_DATA":
			cartData = [];
			return { ...cart, data: cartData, lastUpdated: Date.now() };
		case "SET_CART_PRICES":
			let cartPrices = action.payload;
			return { ...cart, prices: cartPrices };
		case "ADD_QUANTITY":
			cartData[action.payload] = {
				...cartData[action.payload],
				quantity: cartData[action.payload].quantity + 1,
			};

			return { ...cart, data: cartData, lastUpdated: Date.now() };
		case "SUB_QUANTITY":
			if (cartData[action.payload].quantity > 1) {
				cartData[action.payload] = {
					...cartData[action.payload],
					quantity: cartData[action.payload].quantity - 1,
				};
			} else {
				cartData = cartData.filter((product, index2) => {
					return index2 !== action.payload;
				});
			}

			return { ...cart, data: cartData, lastUpdated: Date.now() };
		case "ADD_CART_PRODUCT":
			let found = false;
			cartData.find(function (cProduct, index) {
				if (
					cProduct.id === action.payload.product.id &&
					cProduct.variation_key === action.payload.variationKey
				) {
					let found2 = true,
						found3 = true;
					action.payload.cfValues.forEach((cfValue) => {
						if (
							cProduct.cf_values.filter((cf_value) => {
								return (
									cf_value.id === cfValue.id &&
									cf_value.value === cfValue.value
								);
							}).length === 0
						)
							found2 = false;
					});

					action.payload.addons.forEach((addon) => {
						if (
							cProduct.addons.filter((cProductAddon) => {
								return cProductAddon.id === addon.id;
							}).length === 0
						)
							found3 = false;
					});

					if (found2 && found3) {
						found = index;
						return true;
					}
				}

				return false;
			});

			if (found === false) {
				cartData = [
					...cartData,
					{
						id: action.payload.product.id,
						variation_key: action.payload.variationKey,
						quantity: action.payload.quantity,
						details: action.payload.product,
						cf_values: action.payload.cfValues,
						related_products:
							action.payload.product.related_products,
						added_at: parseInt(Date.now() / 1000),
						addons: action.payload.addons,
					},
				];
			} else {
				cartData[found] = {
					...cartData[found],
					quantity:
						cartData[found].quantity + action.payload.quantity,
				};
			}

			return { ...cart, data: cartData, lastUpdated: Date.now() };
		case "DELETE_CART_PRODUCT":
			cartData = cartData.filter((product, index2) => {
				return index2 !== action.payload;
			});
			return { ...cart, data: cartData, lastUpdated: Date.now() };
		default:
			return { ...cart, data: cartData };
	}
};

export default CartReducer;
