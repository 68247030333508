import React from "react";
import "./Failed.css";
import { Link } from "react-router-dom";

const Failed = () => {
	return (
		<div className="container">
			<div className="d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
				<div>
					<i className="fa-5x pb-2 text-danger fas fa-times-circle"></i>
				<h4 className="text-center">Something Went Wrong!</h4>
				<div className="lead px-1">
					<span>Please try again</span> or contact us.
				</div>

				<Link to="/">
					<div className="mt-3">
						<button className="btn btn-primary btn-sm rounded-pill">
							Continue to homepage
						</button>
					</div>
				</Link>
				</div>
			</div>
		</div>
	);
};

export default Failed;
