import { React, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import CopyRight from "../Footer/CopyRight";
import "../Footer/Footer.css";
import NavbarCheckout from "../Navbar/NavbarCheckout";
import User from "../User/User";
import "./Account.css";
import { ImProfile } from "react-icons/im";
import { FaFileInvoice } from "react-icons/fa";
import Footer from "../Footer/Footer";

const Account = (props) => {
	const categorys = props.categorys;
	const location = useLocation();

	// Handle Tab change state
	const [activeTab, setActiveTab] = useState({
		orders: "tab",
		profile: "tab tab-active",
	});
	const [title, setTitle] = useState("Profile");
	useEffect(() => {
		let activeTabCopy = { ...activeTab };
		if (
			location.pathname === "/account" ||
			location.pathname === "/account/profile"
		) {
			activeTabCopy["profile"] = "tab tab-active";
			activeTabCopy["orders"] = "tab";
			setTitle("Profile Details");
		} else if (location.pathname === "/account/orders") {
			activeTabCopy["orders"] = "tab tab-active";
			activeTabCopy["profile"] = "tab";
			setTitle("Orders Details");
		}
		setActiveTab(activeTabCopy);
	}, [location.pathname]);

	return (
		<>
			<div className="bg-grey account-scroll">
				<NavbarCheckout
					categorys={categorys}
					title={title}
				></NavbarCheckout>

				<div className="pt-3 pb-0">
					<User></User>
				</div>

				<div className="bg-grey pt-2 text-start">
					<div>
						<Outlet />
					</div>
				</div>
				<CopyRight className="mt-3 mb-3" />
				<Footer />
			</div>
		</>
	);
};

export default Account;
