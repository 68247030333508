import { React, useEffect, useState } from "react";
import "./Account.css";
import { Button } from "antd";
import "antd/dist/antd.css";
import { API_ENDPOINT_ACCOUNT_UPDATE, parameters } from "../utilities/Webview";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import useProfileFormValidation from "../../hooks/useProfileFormValidation";
import { postProfile } from "../../api";

const Profile = () => {
	const profile = useSelector(state=>state.profile);
	const dispatch=useDispatch();
	const [name, setName] = useState(profile.display_name);
	const [address, setAddress] = useState(profile.address);
	const [phone, setPhone] = useState(profile.mobile);
	const [email, setEmail] = useState(profile.email);
	const [nameValidationClass, setNameValidationClass] = useState("");
	const [emailValidationClass, setEmailValidationClass] = useState("");
	const [addressValidationClass, setAddressValidationClass] = useState("");
	const [phoneValidationClass, setPhoneValidationClass] = useState("");
	const {phoneValidation,emailValidation,nameValidation}=useProfileFormValidation();
	const [formIsValid, setFormIsValid] = useState(false);

	let navigate = useNavigate();
	let location = useLocation();
	const { from } = location?.state || { from: "" };

	const handleNameValidation = (e) => {
		const {value,isValid}=nameValidation(e.target.value);
		setName(value);
		if (!isValid) {
			setNameValidationClass(" is-invalid");
		} else {
			setNameValidationClass(" is-valid");
		}
	};

	const handleEmailValidation = (e) => {
		const {value, isValid}=emailValidation(e.target.value)
		setEmail(value);

		if (isValid) {
			setEmailValidationClass(" is-valid");
		} else {
			setEmailValidationClass(" is-invalid");
		}
	};

	const handlePhoneValidation = (e) => {
		const {value,isValid}=phoneValidation(e.target.value);
		setPhone(value);
		if (isValid) {
			setPhoneValidationClass(" is-valid");
		} else {
			setPhoneValidationClass(" is-invalid");
		}
	};

	const handleAddressChange = (e) => {
		let value = e.target.value.trim();
		setAddress(value.length > 0 ? value : null);

		if (value.length > 0) {
			setAddressValidationClass(" is-valid");
		} else {
			setAddressValidationClass(" is-invalid");
		}
	};

	const somethingChanged = () => {
		return (
			name !== profile.display_name ||
			email !== profile.email ||
			phone !== profile.mobile ||
			address !== profile.address
		);
	};

	const validateForm = () => {
		return (
			somethingChanged &&
			nameValidationClass !== " is-invalid" &&
			addressValidationClass !== " is-invalid" &&
			emailValidationClass !== " is-invalid" &&
			phoneValidationClass !== " is-invalid"
		);
	};

	useEffect(() => {
		setFormIsValid(validateForm());
	}, [name, email, phone, address]);

	const saveProfile = () => {
		if (formIsValid) {
			postProfile({
				display_name: name,
				address: address,
				mobile: phone,
				email: email,
			})
			.then(function () {
				const newProfile = { ...profile };
				newProfile["display_name"] = name;
				newProfile["address"] = address;
				newProfile["mobile"] = phone;
				newProfile["email"] = email;
				dispatch({type:'update_profile',payload:newProfile})
				toast.success("Your Profile saved", {
					position: "top-center",
					autoClose: 1000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				setTimeout(() => {
					if (from !== null) {
						navigate(from);
					}
				}, 2000);
			});
			setNameValidationClass("");
			setAddressValidationClass("");
			setEmailValidationClass("");
			setPhoneValidationClass("");
		}
	};

	return (
		<div>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-2 profile-input-title">Name</div>
					<div className="col-10">
						<div className="input-group">
							<input
								type="text"
								className={
									"form-control rounded m-2 ms-4 me-0 profile-inputs" +
									nameValidationClass
								}
								value={name}
								onChange={handleNameValidation}
								placeholder="Please enter your name"
								aria-describedby="nameValidation"
							/>
							<div
								id="nameValidation"
								className="invalid-feedback text-center mt-0"
							>
								This field cannot be empty.
							</div>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-2 profile-input-title">Email</div>
					<div className="col-10">
						<div className="input-group">
							<input
								type="email"
								className={
									"form-control rounded m-2 ms-4 me-0 profile-inputs" +
									emailValidationClass
								}
								value={email}
								onChange={handleEmailValidation}
								placeholder="Please enter your email"
								aria-describedby="emailValidation"
							/>
							<div
								id="emailValidation"
								className="invalid-feedback text-center mt-0"
							>
								Enter a valid email.
							</div>
						</div>
					</div>
				</div>

				<div className="row align-items-center">
					<div className="col-2 profile-input-title">Phone</div>
					<div className="col-10">
						<div className="input-group">
							<input
								type="text"
								className={
									"form-control rounded m-2 ms-4 me-0 profile-inputs" +
									phoneValidationClass
								}
								value={phone}
								onChange={handlePhoneValidation}
								placeholder="Please enter your phone number"
								aria-describedby="phoneValidation"
							/>
							<div
								id="phoneValidation"
								className="invalid-feedback text-center mt-0"
							>
								Enter a valid phone number.
							</div>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-2 profile-input-title">Address</div>

					<div className="col-10">
						<div className="input-group pt-2">
							<input
								type="text"
								className={
									"form-control rounded ms-4 text-start profile-inputs" +
									addressValidationClass
								}
								placeholder="Please enter your address"
								value={address}
								onChange={handleAddressChange}
							/>
							<div
								id="addressValidation"
								className="invalid-feedback text-center mt-0"
							>
								This field cannot be empty.
							</div>
						</div>
						{/* <div className="d-flex ms-4 pt-2">
                            <p className="mb-0 text-small">Or, </p>
                            <a href="#" onClick={() => setShow(true)} className="ps-2 text-link map-text text-small"> Set Location on map</a>
                        </div> */}
					</div>
				</div>

				<div className="text-end my-3">
					<Button
						type="primary"
						onClick={saveProfile}
						className={
							"btn btn-md rounded-pill" +
							(formIsValid ? "" : " disabled")
						}
					>
						Save Profile
					</Button>
				</div>
			</div>

			{/* Modal for map  */}
			{/* <div onClick={(e) => e.stopPropagation()}>
				<Modal
					id="modal-product"
					className="modal-dialog-centered modal-p-bottom justify-content-center mx-auto align-items-center"
					show={show}
					onHide={() => setShow(false)}
					dialogClassName="modal-50w"
					aria-labelledby="product-modal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Google Map</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="modal-body-scroll">
							<div className="d-flex flex-column justify-content-center align-items-center ">
								<img
									src=""
									alt=""
									className="img-responsive resized"
								/>
							</div>
							<div className="d-flex flex-column justify-content-center ">
								<h3 className="justify-content-center py-2">
									Hello
								</h3>

								<p className="text-justify font-weight-bolder mx-auto modal-text-line">
									<small>This is map</small>
								</p>
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer className="d-flex justify-content-around">
						<Button variant="outline-primary" onClick={handleClose}>
							Confirm <i className="fas fa-check-circle"></i>
						</Button>
					</Modal.Footer>
				</Modal>
			</div> */}
		</div>
	);
};

export default Profile;
