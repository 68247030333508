import React from 'react'
import { getSettings } from '../../../../utils/sliderSettings'
import Slider from "react-slick";
import Product from '../Product';

const SimilarProductsSection = ({title,products,showToastify,setShowToastify}) => {
  return (
    <div>
      <div className="bd-highlight ps-2 pe-2 text-start mt-3 mb-2">
        <div className="p-2 pt-0 pb-0 bd-highlight">
          <p className="mb-2 text-cart">You May Like</p>
        </div>
      </div>
      <div className="text-center mx-2">
        <div className="pb-2">
          <div className="bg-grey pt-2 pb-1">
            <div className="mx-3">
              <Slider {...getSettings(products)}>
                {products.map((product) => (
                  <Product
                    key={product.title + '_similar'}
                    product={product}
                    cardType="Similar Product"
                    showToastify={showToastify}
                    setShowToastify={setShowToastify}
                  ></Product>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimilarProductsSection
