import React from "react";
import "./Failed.css";
import closedImg from "../../images/closed.png";

const Closed = (props) => {
	const awayMessage = props.awayMessage.replaceAll("\n", "<br>");

	return (
		<div className="container">
			<div
				className="d-flex align-items-center justify-content-center"
				style={{ height: "100vh" }}
			>
				<div>
					<img src={closedImg} />
					<div
						className="lead px-1"
						dangerouslySetInnerHTML={{
							__html: awayMessage,
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default Closed;
