import React from "react";
import "./Footer.css";

const CopyRight = (props) => {
	return (
		<div className={"copyright text-center " + props.className}>
			We're{" "}
			<svg
				width="20"
				height="20"
				viewBox="0 0 91 190"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M89.8656 50.012C91.084 51.5345 91.3209 53.2091 90.5764 55.0361L35.7485 187.145C34.8685 189.048 33.4471 190 31.4841 190C31.2133 190 30.7395 189.924 30.0626 189.772C28.9119 189.391 28.0489 188.668 27.4735 187.602C26.8982 186.536 26.7459 185.395 27.0166 184.177L47.0187 91.9171L5.79622 103.45C5.52546 103.526 5.11933 103.564 4.57782 103.564C3.35942 103.564 2.31025 103.145 1.43029 102.308C0.211895 101.166 -0.228082 99.6815 0.110362 97.8546L20.5185 3.65385C20.7893 2.58814 21.3308 1.71274 22.143 1.02764C22.9553 0.342548 23.903 0 24.986 0H58.2888C59.5749 0 60.6579 0.475761 61.5379 1.42728C62.4178 2.37881 62.8578 3.5016 62.8578 4.79567C62.8578 5.40465 62.6886 6.08974 62.3502 6.85096L44.988 59.7175L85.1951 48.5276C85.7366 48.3754 86.1428 48.2993 86.4135 48.2993C87.6996 48.2993 88.8503 48.8702 89.8656 50.012Z"
					fill="#FFD600"
				/>
			</svg>{" "}
			by LazyChat
		</div>
	);
};

export default CopyRight;
