
export const isInside = (check, data) => {
  const dataPoints = data;
  const TEMP = [];
  for (const point in dataPoints) {
    TEMP.push(
      pointInsideCircle(check, {
        center: dataPoints[point].center,
        radius: dataPoints[point].radius,
      })
    );
  }
  const LOOK = TEMP.includes(true);
  return LOOK;
};

export const pointInsideCircle = (point, circle) => {
  const { center } = circle;
  const distance = distanceBetween(point, center);
  return distance <= circle.radius; // Use '<=' if you want to get all points in the border
};

export const distanceBetween = (point1, point2) => {
  const R = 6371e3; // metres
  const φ1 = toRadians(point1.latitude);
  const φ2 = toRadians(point2.latitude);
  const Δφ = toRadians(point2.latitude - point1.latitude);
  const Δλ = toRadians(point2.longitude - point1.longitude);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export const toRadians = (val) => {
  return (val * Math.PI) / 180;
};