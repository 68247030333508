import React from "react";
import { useNavigate } from "react-router-dom";
import RightSideBar from "./RightSideBar";
import { FiMenu } from "react-icons/fi";
import BackSVG from "../SVGIcons/BackSVG";
import { getBanglaHTML } from "../utilities/Webview";

const NavbarCart = (props) => {
	const navigate = useNavigate();
	const categorys = props.categorys;
	const title = props.title;
	const search = props.search;

	return (
		<>
			<nav className="navbar navbar-expand-lg bg-primary-color text-secondary-color py-0 stick-top">
				<div className="container m-2">
					<div className="d-flex  align-items-center  justify-content-start">
						<div
							className="navbar-brand d-flex"
							onClick={() => navigate(-1)}
						>
							<BackSVG></BackSVG>
							{/* <ion-icon name="chevron-back-circle-outline" height="40px"></ion-icon> */}
						</div>
					</div>
					<div
						className="page-title"
						dangerouslySetInnerHTML={{
							__html: `${getBanglaHTML(title)}`,
						}}
					></div>
					<button
						className="hamburger-toggler d-flex align-items-center"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#navoffcanvas"
						aria-controls="navoffcanvas"
					>
						<FiMenu className="navbar-brand p-0 m-0 text-secondary-color" />
						{/* <i className="fas fa-th navbar-brand"></i> */}
					</button>

					{/*  Right Sidebar */}
					<RightSideBar categorys={categorys}></RightSideBar>
				</div>
			</nav>
			{search && (
				<div className="container-fluid mt-3">
					<div className="input-group mt-2 mb-3 my-2 me-2">
						<input
							type="text"
							className="form-control  searchbox"
							placeholder="Search..."
							aria-label="Search"
							aria-describedby="searchbox"
							onChange={(e) => search(e, e.target.value)}
						/>
						<span
							className="input-group-text searchbox-icon"
							id="searchbox"
						>
							<i className="fas fa-search"></i>
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default NavbarCart;
