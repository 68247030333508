import axios from "axios";
import React, { useEffect, useState } from "react";
import {
	API_ENDPOINT_FILTER,
	getBanglaHTML,
	parameterArray,
	parameters,
} from "../../utilities/Webview";
import "./Category.css";

const Category = (props) => {
	const filterProducts = props.filterProducts;
	const appendOpened = props.appendOpened;
	const setSubcategories = props.setSubcategories;
	const { title, thumbnail, parent_id, subcategories, id } = props.category;
	const expanded = props.expanded;
	const opened = props.opened;
	const [products, setProducts] = useState([]);
	const [fetched, setFetched] = useState(false);
	const setCategoricalProductsTitle = props.setCategoricalProductsTitle;

	const handleProducts = function (products) {
		// if (products.length > 0 || subcategories.length === 0) {
		filterProducts(products);
		setSubcategories(subcategories);
		let tempSubcategories = subcategories;
		tempSubcategories = tempSubcategories.filter((subcategory) => {
			return products.find((product) =>
				product.categories.includes(subcategory.id + "")
			);
		});
		setSubcategories(tempSubcategories);
		// } else {
		// 	let productExists = false;
		// 	subcategories.forEach((subcategory, index) => {
		// 		if (subcategory.subcategories.length > 0) {
		// 			productExists = true;
		// 		}
		// 	})

		// 	if (!productExists) filterProducts([]);
		// }

		appendOpened(id, parent_id);
	};

	const showCategoryProducts = (id) => {
		if (fetched) {
			handleProducts(products);
		} else {
			axios
				.get(`${API_ENDPOINT_FILTER}?${parameters()}&categoryId=${id}`)
				.then((res) => {
					var products = res.data.products;
					setProducts(products);
					handleProducts(products);
					setFetched(true);
				});
		}
	};

	const handleSubcategoryClick = () => {
		showCategoryProducts(id);
	};

	useEffect(() => {
		if (opened[opened.length - 1] === id && fetched) {
			filterProducts(products);
		}
	}, [opened, fetched]);

	const handleOpenedSubcategories = () => {
		if (opened.includes(id)) {
			setCategoricalProductsTitle(title);
			return "sub-category-selected card sub-category items";
		} else {
			return "card sub-category items";
		}
	};

	const handleOpenedCategories = () => {
		if (opened.includes(id)) {
			setCategoricalProductsTitle(title);
			return "card card-size card-bdr category-card d-flex align-items-center justify-content-center";
		} else {
			return "card card-size category-card d-flex align-items-center justify-content-center";
		}
	};
	const handleFeaturedCategoryTitleClass = () => {
		if (opened.includes(id)) return "category-selected-text";
		return "";
	};

	if (parent_id != null) {
		return (
			<div
				className={handleOpenedSubcategories()}
				onClick={handleSubcategoryClick}
			>
				<p
					className="text-center my-auto mx-auto px-2 text-sub-category"
					dangerouslySetInnerHTML={{
						__html: `${getBanglaHTML(title)}`,
					}}
				></p>
			</div>
		);
	}

	return (
		<div
			className="category items"
			onClick={() => showCategoryProducts(id)}
		>
			<div className={handleOpenedCategories()}>
				<img src={thumbnail} className="category-img" alt="..." />
			</div>
			<div
				className={
					"category-title " + handleFeaturedCategoryTitleClass()
				}
				dangerouslySetInnerHTML={{
					__html: `${getBanglaHTML(title)}`,
				}}
			></div>
		</div>
	);
};

export default Category;
