import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AddonsRadioSelection = ({ items, groupId }) => {
	const [value, setValue] = useState(-1);
	const dispatch = useDispatch();
	const handleRadioValueUpdate = (e) => {
		if (+value === +e.target.value) {
			dispatch({
				type: "toggle_addon",
				payload: { item: items[+e.target.value], groupId },
			});
			setValue(-1);
		}
	};
	const handleRadioAddonChange = (e) => {
		if (value !== -1)
			dispatch({
				type: "toggle_addon",
				payload: { item: items[value], groupId },
			});
		setValue(e.target.value);
		dispatch({
			type: "toggle_addon",
			payload: { item: items[+e.target.value], groupId },
		});
	};
	useEffect(() => {
		document.querySelectorAll(".child-row").forEach((el) => {
			const parentEl = el.closest("span");
			parentEl.style.display = "block";
			parentEl.style.width = "100%";
		});
	}, []);
	return (
		<Radio.Group
			value={value}
			className="theme-radios w-100"
			onChange={(e) => handleRadioAddonChange(e)}
		>
			<div className="d-flex flex-column">
				{items.map((item, ind) => (
					<Radio value={ind} onClick={handleRadioValueUpdate}>
						<div className="d-flex justify-content-between child-row addon-item">
							<div className="flex-grow-1 me-4">{item.title}</div>
							<div className="text-end">
								<div className="me-2">৳ {item.price}</div>
							</div>
						</div>
					</Radio>
				))}
			</div>
		</Radio.Group>
	);
};

export default AddonsRadioSelection;
